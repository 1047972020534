import React, { useState } from "react"
import { Link, StaticQuery, graphql } from "gatsby"
import window from "global"
import {
  Nav,
  Navbar,
  Collapse,
  Container,
  DropdownToggle,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import Navitem from "./navitem"
import { FaPhoneAlt, FaEnvelope, FaTwitter, FaFacebookF } from "react-icons/fa"
import { GrInstagram } from "react-icons/gr"
import { AiOutlineMenu } from "react-icons/ai"
import Logo from "../../../images/icons/ribslogo.png"

const MyNav = () => {
  //Nacbar Toggler
  const [isOpen, setIsOpen] = useState(false)
  const toggle = () => setIsOpen(!isOpen)

  //Nacbar Color on Scroll
  window.onscroll = () => {
    const scrollNav = document.querySelector(".head")
    const bodyOrder = document.querySelector("body")
    const scrollMe = window.scrollY
    if (scrollMe >= 100) {
      scrollNav.classList.add("scroll")
      bodyOrder.classList.add("order-b")
    } else {
      scrollNav.classList.remove("scroll")
      bodyOrder.classList.remove("order-b")
    }
  }

  return (
    <StaticQuery
      query={graphql`
        query NavQuery {
          site {
            siteMetadata {
              twitter
              tel

              facebook
              email
              instagram
            }
          }
        }
      `}
      render={data => (
        <div className="head fixed-top">
          <div id="topbar" className="d-lg-flex align-items-center">
            <Container className="d-flex mobile-top">
              <div className="contact-info">
                <span className="phone">
                  <a
                    className="sans tel-email"
                    href={`tel:${data.site.siteMetadata.tel}`}
                  >
                    <FaPhoneAlt size={12} />
                    <span className="sans"> {data.site.siteMetadata.tel}</span>
                  </a>
                </span>
                <span>
                  <a
                    className="sans tel-email"
                    href={`mailto:${data.site.siteMetadata.email}`}
                  >
                    <FaEnvelope size={13} />
                    <span className="sans">
                      {" "}
                      {data.site.siteMetadata.email}
                    </span>
                  </a>
                </span>
              </div>
              <div className="social-links ml-auto">
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href={data.site.siteMetadata.twitter}
                  className="twitter"
                >
                  <FaTwitter size={15} />
                </a>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href={data.site.siteMetadata.facebook}
                  className="facebook"
                >
                  <FaFacebookF size={13} />
                </a>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href={data.site.siteMetadata.instagram}
                  className="instagram"
                >
                  <GrInstagram size={14} />
                </a>
              </div>
            </Container>
          </div>
          <Navbar className="navbar-expand-lg">
            <Container>
              <Link className="navbar-brand d-lg-none" to="/">
                <img src={Logo} alt="Ribs USA" />
              </Link>

              <button
                aria-label="Togglenavigation"
                type="button"
                className="navbar-toggler"
                onClick={toggle}
              >
                <AiOutlineMenu size={25} />
              </button>
              <Collapse
                id="navbarToggle"
                className="justify-content-center"
                isOpen={isOpen}
                navbar
              >
                <Nav navbar>
                  <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle nav caret>
                      Menu
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem>
                        <Navitem name="Full Menu" url="/full-menu" />
                      </DropdownItem>
                      <DropdownItem>
                        <Navitem name="Catering Menu" url="/catering-menu" />
                      </DropdownItem>
                      <DropdownItem>
                        <Navitem name="Kids Menu" url="/kids-menu" />
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                  <Navitem name="Gallery" url="/gallery" />
                </Nav>
                <Link
                  className="navbar-brand d-none d-lg-block text-center"
                  to="/"
                >
                  <img src={Logo} alt="Ribs USA" />
                </Link>
                <Nav navbar>
                  <Navitem name="About" url="/about-us" />
                  <Navitem name="Contact" url="/contact-us" />
                </Nav>
              </Collapse>
            </Container>
          </Navbar>
        </div>
      )}
    />
  )
}

export default MyNav
