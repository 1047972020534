import React from "react"
import { Link, StaticQuery, graphql } from "gatsby"
import { Container } from "reactstrap"
import { FaTwitter, FaFacebookF } from "react-icons/fa"
import { GrInstagram } from "react-icons/gr"

export default function Footer() {
  return (
    <StaticQuery
      query={graphql`
        query FooterQuery {
          site {
            siteMetadata {
              twitter
              tel

              facebook
              email
              instagram
            }
          }
        }
      `}
      render={data => (
        <footer className="site-footer">
          <div className="footer-content">
            <Container>
              <div className="row">
                <div className="col-sm-12 col-md-4 footer-about">
                  <h6>About Us</h6>
                  <p className="text-justify">
                    Ribs U.S.A. is a moderately priced family restaurant
                    featuring high quality barbecued foods. In our relaxed
                    atmosphere guests enjoy complimentary peanuts and tossing
                    the shells on the floor, while sipping beer, wine, or sodas.
                  </p>
                </div>
                <div className="col-sm-12 col-md-4">
                  <h6>Business Hours</h6>
                  <p className="text-justify">
                    <strong>Monday – Thursday</strong>
                    : 11:00 am – 07:30 pm <br />
                    <strong>Friday – Saturday</strong>
                    : 11:00 am – 09:00 pm <br />
                    <strong>Sunday</strong>: 11:00 am – 07:30 pm
                  </p>
                </div>
                <div className="col-sm-12 col-md-4">
                  <h6>Contact Us</h6>
                  <p className="text-justify">
                    <strong>Address</strong>
                    : 2711 W Olive Ave. <br />
                    Burbank, CA 91505 <br />
                    <strong>Phone:</strong>
                    <a
                      className="tel-email-footer"
                      href={`tel:${data.site.siteMetadata.tel}`}
                    >
                      {" "}
                      {data.site.siteMetadata.tel}{" "}
                    </a>
                    <br />
                    <strong>Email:</strong>
                    <a
                      className="tel-email-footer"
                      href={`mailto:${data.site.siteMetadata.email}`}
                    >
                      {" "}
                      {data.site.siteMetadata.email}{" "}
                    </a>
                  </p>
                </div>
              </div>
            </Container>
          </div>
          <div className="copyright">
            <Container>
              <div className="row">
                <div className="col-md-8 col-sm-6 col-xs-12 copyright-text">
                  <p>
                    <Link className="term" to="/terms-conditions">
                      Terms & Conditions
                    </Link>{" "}
                    |{" "}
                    <Link className="term" to="/accessibility">
                      Accessibility
                    </Link>{" "}
                    |{" "}
                    <Link className="term" to="/privacy-policy">
                      Privacy Policy
                    </Link>{" "}
                    | Copyright &copy; 2020, All Rights Reserved by
                    <Link to="/"> Ribs USA</Link>.
                  </p>
                  <p>
                    Restaurent Services by <a href="https://hungryhipposolutions.com">Hungry Hippo Solutions</a>
                  </p>
                </div>
                <div className="col-md-4 col-sm-6 col-xs-12">
                  <div className="social-links-footer">
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href={data.site.siteMetadata.twitter}
                      className="twitter"
                    >
                      <FaTwitter size={15} />
                    </a>
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href={data.site.siteMetadata.facebook}
                      className="facebook"
                    >
                      <FaFacebookF size={13} />
                    </a>
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href={data.site.siteMetadata.instagram}
                      className="instagram"
                    >
                      <GrInstagram size={14} />
                    </a>
                  </div>
                </div>
              </div>
            </Container>
          </div>
        </footer>
      )}
    />
  )
}