import React, { Fragment } from "react"
import PropTypes from "prop-types"
import MyNav from "./navbar/navbar"
import Footer from "./footer/footer"
import { Link } from "gatsby"

const Layout = ({ children }) => {
  return (
    <Fragment>
      <MyNav />
      {children}
      <Footer />
      <div className="nav-item order">
        <Link to="/order-online">Order Online</Link>
      </div>
      <Link to="/order-online" className="order-button">
        Order Online
      </Link>
    </Fragment>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
